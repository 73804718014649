import React from 'react'

import { Box, Flex } from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { Heading } from 'src/components/Heading'
import { LogoBugImage } from 'src/components/LogoBugImage'
import { MarkdownContent } from 'src/components/MarkdownContent'
import { Subheading } from 'src/components/Subheading'
import { TapaPatternFloating } from 'src/components/TapaPatternFloating'

export const DetailedPageIntro = ({
    children,
    imageFluid,
    markdown,
    pageTitle,
    ...props
}) => (
    <BoundedBox
        as="section"
        bg="sandLight"
        maxWidth="large"
        position="relative"
        py={[6, 10]}
        {...props}
    >
        <TapaPatternFloating opacity={0.3} />
        <Flex
            alignItems={[null, 'center']}
            flexDirection={['column', 'row']}
            justifyContent={[null, 'space-between']}
            position="relative"
        >
            <Box width={[1, 6 / 12]} mr={[null, 4]} mb={[4, 0]}>
                <Subheading color="teal" mb={2}>
                    {pageTitle}
                </Subheading>
                {children || (
                    <MarkdownContent
                        markdown={markdown}
                        map={{
                            h1: props => (
                                <Heading
                                    as="h3"
                                    color="white"
                                    fontSize={['xlarge', 'xxlarge']}
                                    fontWeight="normal"
                                    lineHeight="title"
                                    mb={3}
                                    boxStyle="lastNoMargin"
                                    {...props}
                                />
                            ),
                        }}
                        color="navy"
                        fontSize={['medium', 'midLarge']}
                        lineHeight="copy"
                    />
                )}
            </Box>
            <Box width={[1, 4 / 12]}>
                <LogoBugImage
                    fillInner="sandLight"
                    imageFluid={imageFluid}
                    maxWidth={['15rem', 'none']}
                    mx="auto"
                />
            </Box>
        </Flex>
    </BoundedBox>
)
