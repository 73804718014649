import React from 'react'

import { Box, Flex, Text, Link as LinkBase, SVG } from 'system'
import { IconChevronRight } from 'src/components/IconChevronRight'

const Link = ({ to, children, ...props }) => (
  <Box as="li" borderBottom={2} borderColor="yellow">
    <LinkBase to={to} display="block" py={3}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text
          as="span"
          fontSize="midLarge"
          fontFamily="sansCond"
          textStyle="caps"
          mr={2}
        >
          {children}
        </Text>
        <SVG svg={IconChevronRight} x={11} y={18} fill="navy" width="0.75rem" />
      </Flex>
    </LinkBase>
  </Box>
)

export const LinedLinkList = ({ children, ...props }) => (
  <Box as="ul" borderTop={2} borderColor="yellow">
    {children}
  </Box>
)

LinedLinkList.Link = Link
