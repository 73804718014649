import React from 'react'

import {
  Box,
  Flex,
  AspectRatio,
  GatsbyImage,
  GatsbyImageContainer,
} from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { Button } from 'src/components/Button'
import { Heading } from 'src/components/Heading'
import { MarkdownContent } from 'src/components/MarkdownContent'
import { Subheading } from 'src/components/Subheading'

const Card = ({
  imageFluid,
  title,
  descriptionMarkdown,
  buttonText = 'Read More',
  buttonTo,
  ...props
}) => (
  <Box
    flex={[null, '1 1 0px']}
    mb={[4, 0]}
    mr={[null, 4]}
    boxStyle="lastNoMargin"
  >
    {imageFluid && (
      <GatsbyImageContainer mb={4}>
        <AspectRatio x={1} y={1} maxHeight="24rem">
          <GatsbyImage fluid={imageFluid} alt="" />
        </AspectRatio>
      </GatsbyImageContainer>
    )}
    <Subheading as="h3" mb={2}>
      {title}
    </Subheading>
    {descriptionMarkdown && (
      <MarkdownContent markdown={descriptionMarkdown} lineHeight="wide" />
    )}
    {buttonTo && (
      <Button to={buttonTo} mt={4}>
        {buttonText}
      </Button>
    )}
  </Box>
)

export const ArticleCards = ({ children, heading, ...props }) => (
  <BoundedBox as="section" color="navy" {...props}>
    {heading && <Heading mb={4}>{heading}</Heading>}
    <Flex flexDirection={['column', 'row']}>{children}</Flex>
  </BoundedBox>
)

ArticleCards.Card = Card
