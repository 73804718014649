import React from 'react'

import { Button as ButtonBase } from 'src/components/Button'

export const ButtonBordered = ({ color = 'navy', ...props }) => (
  <ButtonBase
    bg="transparent"
    border={2}
    borderColor={color}
    color={color}
    css={`
      transition-duration: ${p => p.theme.transition};
      transition-property: color, background-color, border-color;
      &:hover {
        color: ${p => p.theme.colors.white};
        background-color: ${p => p.theme.colors.navy};
      }
    `}
    {...props}
  />
)
