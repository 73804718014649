import React from 'react'

import { Box } from 'system'
import { ButtonBordered } from 'src/components/ButtonBordered'
import { Heading } from 'src/components/Heading'
import { MarkdownContent } from 'src/components/MarkdownContent'
import { Subheading } from 'src/components/Subheading'

export const CallToActionCard = ({
  title,
  heading,
  descriptionMarkdown,
  buttonTo,
  buttonText = 'Learn More',
  ...props
}) => (
  <Box bg="sandExtraLight" color="navy" p={[3, 4]} {...props}>
    <Subheading mb={[2, 3]}>{title}</Subheading>
    <Heading mb={[2, 3]}>{heading}</Heading>
    <MarkdownContent
      markdown={descriptionMarkdown}
      lineHeight="wide"
      mb={[3, 4]}
    />
    <ButtonBordered to={buttonTo}>{buttonText}</ButtonBordered>
  </Box>
)
