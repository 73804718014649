import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { get, map, isEmpty } from 'lodash/fp'

import { Box, Flex } from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { CallToActionCard } from 'src/components/CallToActionCard'
import { Heading } from 'src/components/Heading'
import { Layout } from 'src/components/Layout'
import { LinedLinkList } from 'src/components/LinedLinkList'
import { Subheading } from 'src/components/Subheading'

import { ArticleCards } from 'src/slices/ArticleCards'
import { DetailedPageIntro } from 'src/slices/DetailedPageIntro'
import { NotificationBar } from 'src/slices/NotificationBar'

const ForPatientsPage = ({ data }) => {
  const page = get('file.childMarkdownRemark', data)
  const blocks = get('frontmatter.features.blocks', page)

  return (
    <Layout>
      <Helmet>
        <title>{get('frontmatter.title', page)}</title>
      </Helmet>
      <DetailedPageIntro
        pageTitle={get('frontmatter.title', page)}
        markdown={get('frontmatter.description', page)}
        imageFluid={get('frontmatter.image.childImageSharp.fluid', page)}
      />
      {get('frontmatter.notice', page) && (
        <NotificationBar>{get('frontmatter.notice', page)}</NotificationBar>
      )}
      {!isEmpty(blocks) && (
        <ArticleCards>
          {map(
            block => (
              <ArticleCards.Card
                key={get('title', block)}
                buttonTo={get('buttonUrl', block)}
                descriptionMarkdown={get('description', block)}
                imageFluid={get('image.childImageSharp.fluid', block)}
                title={get('title', block)}
              />
            ),
            blocks
          )}
        </ArticleCards>
      )}
      <BoundedBox as="section" pt={isEmpty(blocks) ? [6, 10] : 0}>
        <Flex flexDirection={['column', 'row']}>
          <Box width={[1, 1 / 2]} mb={[6, 0]} mr={[null, 4, 10]}>
            {map(
              card => (
                <CallToActionCard
                  key={get('title', card)}
                  buttonTo={get('buttonUrl', card)}
                  descriptionMarkdown={get('description', card)}
                  heading={get('heading', card)}
                  title={get('title', card)}
                  mb={4}
                  boxStyle="lastNoMargin"
                />
              ),
              get('frontmatter.cards', page)
            )}
          </Box>
          <Box width={[1, 1 / 2]} pt={[null, 4]}>
            <Subheading mb={[2, 3]}>Patient Resources</Subheading>
            <Heading mb={[3, 4]}>
              Learn more about the areas we specialize in.
            </Heading>
            <LinedLinkList>
              {map(
                resource => (
                  <LinedLinkList.Link
                    key={get('title', resource)}
                    to={get('buttonUrl', resource)}
                  >
                    {get('title', resource)}
                  </LinedLinkList.Link>
                ),
                get('frontmatter.resources', page)
              )}
            </LinedLinkList>
          </Box>
        </Flex>
      </BoundedBox>
    </Layout>
  )
}

export default ForPatientsPage

export const query = graphql`
  query ForPatientsPage {
    file(relativePath: { eq: "customPages/for-patients.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
          description
          notice
          image {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          # features {
          #   blocks {
          #     title
          #     description
          #     buttonUrl: button_url
          #     image {
          #       childImageSharp {
          #         fluid(maxWidth: 800, quality: 90) {
          #           ...GatsbyImageSharpFluid_withWebp_noBase64
          #         }
          #       }
          #     }
          #   }
          # }
          cards {
            title
            heading
            description
            buttonUrl: button_url
          }
          resources {
            title
            buttonUrl: button_url
          }
        }
      }
    }
  }
`
